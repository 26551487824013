import React from "react";
import { css } from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

const pageStyles = css``;

const formStyles = css`
  max-width: 400px;

  .form-group {
    margin-bottom: 10px;
  }

  fieldset {
    margin-bottom: 20px;
  }

  input,
  textarea {
    margin-bottom: 10px;
    border: 1px solid #ddd;

    padding: 5px 0 5px 5px;

    transition: all 0.3s ease-in-out;
    width: 100%;
  }

  label {
    display: block;
  }

  .label_subtext {
    font-size: var(--typeScaleSmall);
  }

  textarea {
    height: 100px;
    overflow: auto;
  }
`;

const Contact = () => {
  return (
    <Layout>
      <SEO title="Get In Touch" />
      <section css={pageStyles}>
        <h1>Get In Touch</h1>

        <p>
          Have a suggestion for the website? Correction for a restaurant?
          Contact us using the form below, or by email at{" "}
          <a href="mailto:david@eatnearme.ca">david@eatnearme.ca</a>
        </p>

        <form
          css={formStyles}
          name="feedback"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="feedback" />
          <input type="hidden" name="bot-field" />

          <div className="form-group">
            <label htmlFor="email">Your Email</label>
            <input id="email" name="email" type="text" required />
          </div>

          <div className="form-group">
            <label htmlFor="restaurant-content">Your Message</label>
            <textarea name="content" id="content" placeholder="" />
          </div>

          <button type="submit">Send</button>
        </form>
      </section>
    </Layout>
  );
};

export default Contact;
